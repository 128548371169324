// import './App.css';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import LoginCallback from './components/LoginCallback';
import SecureRoute from './components/SecureRoute';

const oktaAuth = new OktaAuth({
  issuer: 'https://weldnorthed.okta.com/oauth2/default',
  clientId: '0oam3h21v2vMvLkBr4x7',
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'groups'],
  pkce: true,
});

function App() {
  const navigate = useNavigate();
  
  const restoreOriginalUri = async(_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route path='/login/callback' element={<LoginCallback />} />
        <Route path='*' element={<SecureRoute />} />
      </Routes>
    </Security>
  );
}

export default App;