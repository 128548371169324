export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';
export const RESET_PROJECT_STATE = 'RESET_PROJECT_STATE';
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_ALL_PROJECT_SUCCESS = 'GET_ALL_PROJECT_SUCCESS';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';
export const itemsPerDashboardPage = 12;
export const itemsPerProjectPage = 10;

export const projectTableColumns = [
    {
        title: "Project",
        dataIndex: "appName",
        key: "project"
    },
    {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        render: (text) => new Date(text).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        })
    }
    ]