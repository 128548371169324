import { ADD_PROJECT_REQUEST, ADD_PROJECT_SUCCESS, ADD_PROJECT_FAILURE, RESET_PROJECT_STATE,
  GET_PROJECT_REQUEST, GET_PROJECT_SUCCESS, GET_PROJECT_FAILURE,
  GET_ALL_PROJECT_SUCCESS
 } from './constants';

const initialState = {
  loading: false,
  addedProject: null,
  addProjectError: null,
  loadingProjects: false,
  allProjects: null,
  projects: null,
  getProjectError: null,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        addedProject: action.payload,
        addProjectError: null,
      };
    case ADD_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        addedProject: null,
        addProjectError: action.payload,
      };
    case GET_PROJECT_REQUEST:
        return {
          ...state,
          loadingProjects: true,
        };
      case GET_ALL_PROJECT_SUCCESS:
        return {
          ...state,
          loadingProjects: false,
          allProjects: action.payload,
          projects: null,
          getProjectError: null
        };
      case GET_PROJECT_SUCCESS:
        return {
          ...state,
          loadingProjects: false,
          projects: action.payload,
          getProjectError: null,
        };
      case GET_PROJECT_FAILURE:
        return {
          ...state,
          loadingProjects: false,
          allProjects: null,
          projects: null,
          getProjectError: action.payload,
        };
    case RESET_PROJECT_STATE:
      return {
        ...state,
        loading: false,
        addedProject: null,
        addProjectError: null,
      };
    default:
      return state;
  }
};

export default projectReducer;