import React, {useEffect, useState} from 'react';
import { Card, Row, Col, Button, Layout, Input, Avatar, Tabs, Modal, Spin, Pagination } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import logo from "../assets/logo.png";
import './HomePage.css';
import Projects from './Projects';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from "./projectActions";
import "../styles/Pagination.css";
import {itemsPerDashboardPage} from "./constants";

const { Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;

const HomePage = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [projectData, setProjectData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [userInitials, setUserInitials] = useState("");
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { loadingProjects, projects, allProjects } = useSelector((state) => state.project);
    const [currentPage, setCurrentPage] = useState(1);
    const [allProjectsData, setAllProjectsData] = useState([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [searchedProjects, setSearchedProjects] = useState(null);
    const [lastUpdateSort, setLastUpdateSort] = useState(false);
    const [alphabeticalSort, setAlphabeticalSort] = useState(false);
    const [sortedProjects, setSortedProjects] = useState([]);
    const [searchMode, setSearchMode] = useState(false);

    useEffect(() => {
        dispatch(getProjects());
        const fetchUser = async () => {
            if(!userInitials){
                try {
                    const idToken = JSON.parse(localStorage.getItem("okta-token-storage")).idToken;
                    const user = idToken.claims.name;
                    const userInitials = user && user.split(' ').map(n => n[0]).join('');
                    setUserInitials(userInitials);
                  } catch (error) {
                    console.error('Error fetching user:', error);
                    setUserInitials(null);
                  } finally {
                      setLoading(false);
                  }
            }
          };
          fetchUser();
    }, [dispatch, activeTab]);

    useEffect(() => {
        if(!(alphabeticalSort || lastUpdateSort || searchMode)){
            dispatch(getProjects(itemsPerDashboardPage, lastEvaluatedKey, currentPage));
        }
        window.scrollTo(0, 0);
    },[dispatch, currentPage, lastEvaluatedKey, activeTab])

    useEffect(() => {
        if(allProjects && allProjects.data){
            setAllProjectsData(allProjects.data); 
        }
        if(projects && projects.data){
            setProjectData(projects.data);
            setLastEvaluatedKey(projects.lastEvaluatedKey);
        }
    },[loadingProjects, projects, allProjects])

    const handleTileClick = (project) => {
        window.open(project.appUrl, "_blank");
    }

    useEffect(() => {
        if (currentPage === 1 && searchedProjects && searchedProjects.length <= itemsPerDashboardPage) {
            setProjectData(searchedProjects);
        }
    }, [currentPage, searchedProjects]);

    useEffect(() => {
        if(!searchMode && (alphabeticalSort || lastUpdateSort)){
            const startIndex = (currentPage - 1) * itemsPerDashboardPage;
            const endIndex = startIndex + itemsPerDashboardPage;
            let data = allProjectsData;
            if(alphabeticalSort){
                data = [...data].sort((a, b) => a.appName.localeCompare(b.appName));
            } else if(lastUpdateSort){
                data = [...data].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
            }
            setSortedProjects(data);
            setProjectData([...data].slice(startIndex, endIndex));
        }
    },[searchMode])

    const handleSearchClick = (e) => {
        let searchedValue = e.target.value;
        if(searchedValue.length >= 3) {
            let searchedProjects = allProjectsData.filter(project => project.appName.toLowerCase().includes(searchedValue.toLowerCase()));
            if(searchedProjects && searchedProjects.length > 0) {
                searchedProjects = alphabeticalSort ? [...searchedProjects].sort((a, b) => a.appName.localeCompare(b.appName)) :
                    lastUpdateSort ? [...searchedProjects].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)) : searchedProjects;
                if(searchedProjects.length <= itemsPerDashboardPage){
                    setCurrentPage(1);
                } else{
                    const totalPages = Math.ceil(searchedProjects.length / itemsPerDashboardPage);
                    const validCurrentPage = currentPage > totalPages ? totalPages : currentPage;
                    const startIndex = (validCurrentPage - 1) * itemsPerDashboardPage;
                    const endIndex = startIndex + itemsPerDashboardPage;
                    setProjectData([...searchedProjects].slice(startIndex, endIndex));
                }
                setErrorMessage("")
            } else{
                setErrorMessage("No matching results");
            }
            setSearchedProjects(searchedProjects)
            setSearchMode(true);
        } else{
            setSearchMode(false);
            setErrorMessage("");
            setSearchedProjects(null)
            const startIndex = (currentPage - 1) * itemsPerDashboardPage;
            const endIndex = startIndex + itemsPerDashboardPage;
            setProjectData([...allProjectsData].slice(startIndex, endIndex));
        }
        setSearchValue(searchedValue)
    }

    const handleAlphabetSort = () => {
        setLastUpdateSort(false);
        setAlphabeticalSort(!alphabeticalSort);
        if(!alphabeticalSort){
            let listOfProjects = searchMode && searchedProjects.length ? [...searchedProjects] : [...allProjectsData];
            let sortedProjects = [...listOfProjects].sort((a, b) => a.appName.localeCompare(b.appName));
            const startIndex = (currentPage - 1) * itemsPerDashboardPage;
            const endIndex = startIndex + itemsPerDashboardPage;
            setSortedProjects(sortedProjects);
            setProjectData([...sortedProjects].slice(startIndex, endIndex));
        }else {
            setSortedProjects([]);
        }
    }   

    const handleLastUpdateSort = () => {
        setAlphabeticalSort(false);
        setLastUpdateSort(!lastUpdateSort);
        if(!lastUpdateSort){
            let listOfProjects = searchMode && searchedProjects.length ? [...searchedProjects] : [...allProjectsData];
            let sortedProjects = [...listOfProjects].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
            const startIndex = (currentPage - 1) * itemsPerDashboardPage;
            const endIndex = startIndex + itemsPerDashboardPage;
            setSortedProjects(sortedProjects);
            setProjectData([...sortedProjects].slice(startIndex, endIndex));
        }else{
            setSortedProjects([]);
        }
    }

    const handleProjectInfo = (project) => {
        Modal.info({
            id: project.appName + " modal",
            title: project.appName,
            content: project.description,
            okText: "Acknowledge",
            onOk() {}
        })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        if(alphabeticalSort || lastUpdateSort || searchMode){
            const startIndex = (page - 1) * itemsPerDashboardPage;
            const endIndex = startIndex + itemsPerDashboardPage;
            const data = searchMode && searchedProjects.length ? searchedProjects : sortedProjects;
            setProjectData([...data].slice(startIndex, endIndex));
        }
    };

    useEffect(() => {
        // Add class to parent .ant-pagination-item element when it contains an ellipsis span
        const ellipsisItems = document.querySelectorAll('.ant-pagination-item-ellipsis');
        ellipsisItems.forEach(item => {
            const parent = item.closest('.ant-pagination-item');
            if (parent) {
                parent.classList.add('non-clickable');
            }
        });
    });

    const itemRender = (current, type, originalElement) => {
        const totalPages = Math.ceil((searchMode && searchedProjects ? searchedProjects.length : allProjects && allProjects.totalCount) / itemsPerDashboardPage);

        if (type === 'page') {
            if (totalPages <= 5) {
                return originalElement;
            }
    
            if (current === 1 || current === totalPages) {
                return originalElement;
            }
    
            if (currentPage <= 3) {
                if (current <= 4) {
                    return originalElement;
                }
                if (current === 5) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            } else if (currentPage >= totalPages - 2) {
                if (current >= totalPages - 3) {
                    return originalElement;
                }
                if (current === totalPages - 4) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            } else {
                if (current === currentPage - 1 || current === currentPage || current === currentPage + 1) {
                    return originalElement;
                }
                if (current === currentPage - 2 || current === currentPage + 2) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            }
            return null;
        }
        if (type === 'jump-prev' || type === 'jump-next') {
            return null; // Hide the jump-prev and jump-next buttons
        }
        return originalElement;
    };

    return (
        <>
        <div style={{height: 1000}}>
          <Row justify="space-between" align="middle" style={{ backgroundColor: 'white', width: '100%', zIndex: 1000, position: "fixed"}}>
              <Col>
                  <Row>
                      <img src={logo} alt="Logo" style={{ height: 40, margin: 10 }} />
                      <Tabs defaultActiveKey="1" onChange={(key) => {setActiveTab(key);}}>
                          <TabPane tab="Dashboard" key="1"/>
                          <TabPane tab="Projects" key="2"/>
                      </Tabs>
                  </Row>
              </Col>
              <Col>
                  <Row>
                      <Search id="searchInput" placeholder="Search projects" style={{ width: 200, marginRight: 10 }} 
                          onChange={handleSearchClick} />
                      <Avatar id="loggedUser" style={{ backgroundColor: '#87d068', marginRight: 10 }}>
                          {userInitials}
                      </Avatar>
                  </Row>
              </Col>
          </Row>
          <br />
          <Content style={{ padding: '0 50px', marginTop: "60px"}}>
              {activeTab === '1' && (
                <Spin spinning={loading || loadingProjects} tip="Loading...">
              <Card style={{justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                  <h1 className='margin-5'>Welcome to AICC Labs Dashboard</h1>
                  <p style={{ fontStyle: 'italic' }}>Here you can find all AICC projects</p>
                  <Row>
                      <p className='margin-5'>Sort By: </p>
                      <Button id="btn-lastUpdate" className={lastUpdateSort ? 'highlight margin-5' : 'margin-5'} onClick={handleLastUpdateSort}>Last Update</Button>
                      <Button id="btn-alphabetical" className={alphabeticalSort ? 'highlight margin-5' : 'margin-5'} onClick={handleAlphabetSort}>Alphabetical</Button>
                  </Row>
                  {errorMessage ? <p>{errorMessage}</p> :
                  <Row gutter={16}>
                      {projectData && projectData.map((project, index) => (
                          <Col key={index} span={6}>
                              <Card 
                                    className='project-card'
                                    hoverable
                                    title={project.appName}
                                    cover={
                                        <img
                                            src={project.imageURL}
                                            alt={project.appName}
                                            className="project-image"
                                            width="1792" 
                                            height="1024"
                                        />
                                    }  
                                    onClick={() => handleTileClick(project)}
                                  id={project.appName}
                                  extra={<Button id={project.appName + " info"} onClick={e => {e.stopPropagation(); handleProjectInfo(project)}} shape="circle" icon={<InfoOutlined />}/>}
                              >
                              </Card>
                              <br />
                          </Col>
                      ))}
                  </Row>
                  }
              </Card>
              <div style={{ marginTop: '20px' }}>
                {projectData && (
                    <Pagination
                        align="end"
                        current={currentPage}
                        pageSize={itemsPerDashboardPage}
                        total={searchMode && searchedProjects ? searchedProjects.length : allProjects && allProjects.totalCount}
                        onChange={handlePageChange}
                        showSizeChanger={false} // number of projects per page can be changed using this as true, 
                        //by default true for total items > 50
                        showTitle={false}// to hide default tooltip
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}//to display custom text
                        itemRender={itemRender} //to show customazed pagination
                    />
                )}
            </div>
                </Spin>
              )}
              {activeTab === '2' && (
                  <Projects searchedValue={searchValue}/>
              )}
          </Content>   
      </div>
      </>
    );
};

export default HomePage;