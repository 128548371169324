import React from 'react';
import './AuthorizationError.css';

const AuthorizationError = ({ error }) => {
  return (
    console.log("error", error),
    <>
      <header className="okta-header">
        <h1>Okta</h1>
      </header>
      <div className="container">
        <div className="errorBox">
          <h1 className="title">Authorization Error</h1>
          <p className="message">We're sorry, you do not have permission to access this application.</p>
          <p className="resolution">Please contact your administrator.</p>
        </div>
      </div> 
    </>
  );
};

export default AuthorizationError;    