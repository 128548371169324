import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import { Table, Spin } from 'antd';
import { projectTableColumns, itemsPerProjectPage } from "./constants";
import ProjectDetails from "./ProjectDetails";
import AddProject from "./AddProject";
import { useSelector } from 'react-redux';

const Projects = (props) => {
    const [searchedProjects, setSearchedProjects] = useState([]);
    const [selectedProjectData, setSelectedProjectData] = useState({});
    const [addProjectScreen, setAddProjectScreen] = useState(false); 
    const { loadingProjects, allProjects } = useSelector((state) => state.project);
    const [currentPage, setCurrentPage] = useState(1);

    const columnsWithSorter = projectTableColumns.map(column => {
        if (column.dataIndex === 'appName') {
            return {
                ...column,
                sorter: (a, b) => a.appName.localeCompare(b.appName)
            };
        } else if (column.dataIndex === 'lastUpdated') {
            return {
                ...column,
                sorter: (a, b) => new Date(a.lastUpdated) - new Date(b.lastUpdated)
            };
        }
        return column;
    });

    useEffect(() => {
        let searchedProjects = allProjects && allProjects.data.filter(project => project.appName.toLowerCase().includes(props.searchedValue.toLowerCase()));
        setSearchedProjects(searchedProjects);
    },[props.searchedValue, allProjects])

    useEffect(() => {
        if(allProjects && allProjects.data)
            setSearchedProjects(allProjects.data);
    },[allProjects])

    const handleTableRowClick = (record) => {
        setSelectedProjectData(record);
    }

    const handleBackButton = () => {
        setSelectedProjectData({});
        setAddProjectScreen(false);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        // Add class to parent .ant-pagination-item element when it contains an ellipsis span
        const ellipsisItems = document.querySelectorAll('.ant-pagination-item-ellipsis');
        ellipsisItems.forEach(item => {
            const parent = item.closest('.ant-pagination-item');
            if (parent) {
                parent.classList.add('non-clickable');
            }
        });
    });

    const itemRender = (current, type, originalElement) => {
        const totalPages = searchedProjects ? Math.ceil(searchedProjects.length / itemsPerProjectPage) : 0;
        if (type === 'page') {
            if (totalPages <= 5) {
                return originalElement;
            }
    
            if (current === 1 || current === totalPages) {
                return originalElement;
            }
    
            if (currentPage <= 3) {
                if (current <= 4) {
                    return originalElement;
                }
                if (current === 5) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            } else if (currentPage >= totalPages - 2) {
                if (current >= totalPages - 3) {
                    return originalElement;
                }
                if (current === totalPages - 4) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            } else {
                if (current === currentPage - 1 || current === currentPage || current === currentPage + 1) {
                    return originalElement;
                }
                if (current === currentPage - 2 || current === currentPage + 2) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            }
            return null;
        }
        if (type === 'jump-prev' || type === 'jump-next') {
            return null; // Hide the jump-prev and jump-next buttons
        }
        return originalElement;
    };

    return(
        <>
        <Spin spinning={loadingProjects} tip="Loading...">
            {selectedProjectData && Object.keys(selectedProjectData).length > 0 ? <ProjectDetails projectData={selectedProjectData} handleBackButton={handleBackButton}/> 
            : addProjectScreen ? <AddProject setAddProjectScreen={setAddProjectScreen} handleBackButton={handleBackButton}/> 
            : <div>
                <h1>Projects</h1>
                <h3>Projects</h3>
                <div style={{ display: "flex" }}>
                    <Button id="btn-addProject" className='margin-5' type="primary" 
                        style={{ marginLeft: "auto" }} onClick={() => setAddProjectScreen(true)}>Add Project</Button>
                </div>
                <Table 
                    id="projectsTable"
                    dataSource={searchedProjects} 
                    columns={columnsWithSorter} 
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: false,
                        current:currentPage,
                        pageSize:itemsPerProjectPage,
                        total:searchedProjects ? searchedProjects.length : 0,
                        showTitle:false,// to hide default tooltip
                        onChange: handlePageChange,
                        showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`, //to display custom text
                        itemRender: itemRender//to show customized pagination
                    }}
                    onChange={() => {window.scrollTo(0, 0);}}
                    rowKey={record => record.appName}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { handleTableRowClick(record) }
                        };
                    }}
                />
                </div>}
            </Spin>
        </>
    )
}

export default Projects;