import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import AuthorizationError from './AuthorizationError';

const LoginCallback = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  const [handledRedirect, setHandledRedirect] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleRedirect = async () => {
      if (!handledRedirect) {
        const urlParams = new URLSearchParams(window.location.search);
        const error = urlParams.get('error');

        if (error) {
          setError(error);
          return;
        }
        try {
          await oktaAuth.handleLoginRedirect();
          setHandledRedirect(true);
          navigate('/');
        } catch (e) {
          console.log(e);
          setError('Authentication Error');
        };
      }
    };

    handleRedirect();
  }, [oktaAuth, navigate, handledRedirect]);

  if (error) {
    return <AuthorizationError error={error} />;
  }

  if (!authState) {
    return <div>Loading...</div>;
  }

};

export default LoginCallback;