import React, { useEffect, useState } from "react";
import { Row, Button, Card, Input, Form, Modal, notification, Spin } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { addProject, getProjects, resetProjectState } from "./projectActions";

const {TextArea} = Input;

const AddProject = (props) => {
    const [projectName, setProjectName] = useState("")
    const [projectLink, setProjectLink] = useState("")
    const [projectDescription, setProjectDescription] = useState("")
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { loading, addProjectError, addedProject, allProjects } = useSelector((state) => state.project);

    const handleSaveBtnClick = () => {
        const newProject = {
            appName: projectName,
            appUrl: projectLink,
            description: projectDescription
        }
        dispatch(addProject(newProject));
    }

    useEffect(() => {
        if (addProjectError) {
          notification.error({
            message: 'Error',
            description: addProjectError,
          });
        } else if (addedProject) {
            notification.success({
                message: 'Success',
                description: addedProject.message,
            });
            setProjectName("");
            setProjectLink("");
            setProjectDescription("");
            dispatch(getProjects());
            props.handleBackButton()
        }
      }, [addProjectError, addedProject]);

    const handleCancelBtnClick = () => {
        Modal.confirm({
            id: "cancelBtnClickModal",
            title: "Warning",
            content: "Added data will not be saved",
            okText: "Back",
            cancelText: "Cancel",
            onCancel: () => {
                props.handleBackButton()
            },
            onOk() {}
        })
    }

    useEffect(() => {
        return () => {
          dispatch(resetProjectState());
        };
      }, []);

    return(
        <>
        <Spin spinning={loading} tip="Loading...">
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Add Project</h1>
                <Button id="btn-backToProject" onClick={() => props.handleBackButton()}>Back</Button>
            </Row>
            <Form form={form} onFinish={handleSaveBtnClick}>
                <Card style={{margin: "10px"}}>      
                    <p>Project Name</p>
                    <Form.Item
                        name="projectName"
                        rules={[
                        {
                            required: true,
                            message: "Please enter Project name",
                        },
                        {
                            min: 3,
                            message: "Project name should be of minimum 3 characters",
                        },
                        () => ({
                            validator(_, value) {
                            if(allProjects && allProjects.data && allProjects.data.filter(project => project.appName.toLowerCase() === value.toLowerCase()).length > 0) {
                                return Promise.reject(new Error('Project already exists with same name'));
                            }else return Promise.resolve();}
                        })
                        ]}
                    >
                        <Input type="text" id="projectName" placeholder="Enter project name" style={{width: "50%"}}  
                           onChange={(e) => {
                                setProjectName(e.target.value);
                                form.setFieldsValue({ projectName: e.target.value });
                                form.validateFields(['projectName']);
                          }}/><br />
                    </Form.Item>
                    <p>Project Link</p>
                    <Form.Item
                        name="projectLink"
                        rules={[
                        {
                            required: true,
                            message: "Please enter Project link",
                        },
                        {
                            type: "url",
                            message: "Please enter Project link as valid URL",
                        }
                        ]}
                    >
                        <Input type="url" id="projectLink" placeholder="Enter project link" style={{width: "50%"}}
                            onChange={(e) => {
                                setProjectLink(e.target.value);
                                form.setFieldsValue({ projectLink: e.target.value });
                                form.validateFields(['projectLink']);
                          }
                            }/><br />
                    </Form.Item>
                    <p>Project Description</p>
                    <Form.Item
                        name="projectDescription"
                        rules={[
                        {
                            required: true,
                            message: "Please enter Project description",
                        },
                        () => ({
                            validator(_, value) {
                            if(value && value.trim() === "") {
                                return Promise.reject(new Error('Please enter Project description'));
                            }else return Promise.resolve();}
                        })
                        ]}
                    >
                        <TextArea id="projectDescription" placeholder="Enter project description" style={{width: "50%"}}
                            onChange={(e) => {
                                setProjectDescription(e.target.value.trim());
                                form.setFieldsValue({ projectDescription: e.target.value });
                                form.validateFields(['projectDescription']);
                          }}/><br />
                    </Form.Item>
                    <Button id="btn-generateImage">Generate Image</Button>
                </Card>
            <Row>
                <Form.Item>
                    <Button id="btn-addProject" type="primary" htmlType="submit" style={{marginRight: "10px"}}
                        disabled={!(projectName && projectLink && projectDescription)}>Save</Button>
                </Form.Item>
                <Button id="btn-cancelAddProject" onClick={handleCancelBtnClick}>Cancel</Button>
            </Row>
            </Form> 
            </Spin>
        </>
    )
}

export default AddProject;