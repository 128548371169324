import React, {useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import HomePage from './HomePage';
import { Provider } from 'react-redux';
import store from './store';

const SecureRoute = () => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      oktaAuth.signInWithRedirect().catch(err => {
        console.error('Error during sign-in redirect:', err);
      });
    }
  }, [authState, oktaAuth]);

  
  if (!authState) {
    return <div>Loading...</div>;
  }

  return authState && authState.isAuthenticated ? (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Provider>
  ) : (
    null
  );
};

export default SecureRoute;