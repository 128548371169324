import React, {useEffect} from "react";
import { Button, Row } from "antd";

const ProjectDetails = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const handleOpenProjectClick = () => {
        window.open(props.projectData.appUrl, "_blank");
    }

    return (
        <div>
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>{props.projectData.appName}</h1>
                <Button id="btn-backToProject" onClick={() => props.handleBackButton()}>Back</Button>
            </Row>
            <Row>
                <img style={{"width": "10%"}} src={props.projectData.imageUrl} alt={props.projectData.appName} />
                <p style={{width: "50%", margin: "5px"}}>{props.projectData.description}</p>
                <Button id="btn-editProject" style={{margin: "5px"}}>Edit Project</Button>
                <Button id="btn-deleteProject" style={{margin: "5px"}}>Delete Project</Button>
                <Button id="btn-openProject" style={{margin: "5px"}} type="primary" onClick={handleOpenProjectClick}>Open Project</Button>
            </Row>
        </div>
    )
}

export default ProjectDetails;